<template>
    <div class="personalCenter">
        <div class="body">
            <div class="body_mine">
                <span style="position: absolute; display: table-cell; white-space: nowrap; padding: 7px; color: #c85808;" v-if="memberInfo.blacklist == 1">您已被列入黑名单，请联系管理员!</span>
                <div class="body_left">
                    <el-col :span="12">
                        <h2>用户中心</h2>
                        <el-menu
                                default-active="2"
                                class="el-menu-vertical-demo"
                                @open="handleOpen"
                                @close="handleClose"
                                default-active = "/main/list_in"
                                :default-openeds="['1','2','3','4']">
                            <el-submenu index="1">
                                <template slot="title">
                                    <i class="el-icon-location"></i>
                                    <span>我的订单</span>
                                </template>
                                <el-menu-item-group>
                                    <el-menu-item index="/main/1list" @click="message(7)">
                                        我的参与
                                    </el-menu-item>
                                    <el-menu-item index="/main/list_in" @click="message(5)">
                                        已中标
                                    </el-menu-item>
                                    <el-menu-item index="/main/list" @click="message(6)">
                                        未中标
                                    </el-menu-item>
                                    <el-menu-item index="/main/list_c" @click="message(10)">
                                        我的收藏
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
                            <el-submenu index="3">
                                <template slot="title">
                                    <i class="el-icon-user-solid"></i>
                                    <span>基本设置</span>
                                </template>
                                <el-menu-item-group>
                                    <el-menu-item index="/main/1RenList" @click="message(1)" v-if="disMemberdis">
                                        用户基本信息
                                    </el-menu-item>
                                    <el-menu-item index="/main/RenList" @click="message(2)" v-if="disCompanydis">
                                        企业基本信息
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
                            <el-submenu index="4">
                                <template slot="title">
                                    <i class="el-icon-s-comment"></i>
                                    <span>消息中心</span>
                                </template>
                                <el-menu-item-group>
                                    <el-menu-item index="/main/msg1" @click="message(99)">
                                        我的消息
                                    </el-menu-item>
                                </el-menu-item-group>
                            </el-submenu>
                            <el-divider></el-divider>
                            <el-menu-item index="5">
                                <i class="el-icon-setting"></i>
                                <span slot="title">关于我们</span>
                            </el-menu-item>
                        </el-menu>
                    </el-col>
                </div>
                <!--   我的参与     -->
                <div class="body_right" v-if="order == 7 ? !disOrder : disOrder">
                    <el-table :data="biddingList" border style="width: 100%">
                        <el-table-column
                                prop="projectno"
                                header-align="center"
                                align="center"
                                label="项目编号">
                        </el-table-column>
                        <el-table-column
                                prop="targetname"
                                header-align="center"
                                align="center"
                                label="项目名称">
                            <template slot-scope="scope">
                                <span @click="gotoEcCollectionsDetails(scope.row)" class="gotoDetails">
                                    {{scope.row.targetname}}
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column
                                prop="outbidstate"
                                header-align="center"
                                align="center"
                                label="竞价状态"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.outbidstate === 0">未开始</span>
                                <span v-if="scope.row.outbidstate === 1">竞拍中</span>
                                <span v-if="scope.row.outbidstate === 2">已结束</span>
                                <span v-if="scope.row.outbidstate === 3">已结束</span>
                                <span v-if="scope.row.outbidstate === 4">等待结果</span>
                                <span v-if="scope.row.outbidstate === 5">公示期</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="baomingaditstate"
                                header-align="center"
                                align="center"
                                label="报名审核状态"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.baomingaditstate === 0">待审核</span>
                                <span v-if="scope.row.baomingaditstate === 1">通过</span>
                                <span v-if="scope.row.baomingaditstate === 2">驳回</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="erroMsg"
                                header-align="center"
                                align="center"
                                label="驳回原因">
                            <template slot-scope="scope">
                                {{scope.row.erroMsg}}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="200" v-if="false">
                            <template slot-scope="scope">
                                <el-button type="text" size="small"
                                           @click="showFile(scope.row.targetid)">资料下载
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="block" align="right">
                        <el-pagination
                                @size-change="sizeChangeHandle4"
                                @current-change="currentChangeHandle4"
                                :current-page="pageIndex4"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize4"
                                :total="totalPage4"
                                layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </div>
                <!-- 已中标流程 -->
                <flowPath v-if="ziLiaovisible" ref="flowpathref"></flowPath>
                <!-- 下载附件页面-->
                <fileView v-if="fileVewvisible" ref="fileVewref"></fileView>
                <!--  已中标   -->
                <div class="body_right" v-if="order == 5 ? !contract : contract">
                    <el-tabs v-model="activeTabName" @tab-click="handleClick">
                        <el-tab-pane label="所有中标" name="first"></el-tab-pane>
                        <el-tab-pane label="待上传" name="second">
                            <span slot="label">待上传<el-badge is-dot class="item" v-if="whitedian_up"></el-badge></span>
                        </el-tab-pane>
                        <el-tab-pane label="待下载" name="third">
                            <span slot="label">待下载<el-badge is-dot class="item" v-if="whitedian_down"></el-badge></span>
                        </el-tab-pane>
                    </el-tabs>
                    <el-table v-if="hetong == 0" :data="bidingInList" border style="width: 100%">
                        <el-table-column
                                prop="projectno"
                                header-align="center"
                                align="center"
                                label="项目编号">
                        </el-table-column>
                        <el-table-column
                                prop="targetname"
                                header-align="center"
                                align="center"
                                label="项目名称">
                            <template slot-scope="scope">
                                <span @click="gotoEcCollectionsDetails(scope.row)" class="gotoDetails">
                                    {{scope.row.targetname}}
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column
                                prop="outbidstate"
                                header-align="center"
                                align="center"
                                label="竞价状态"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.outbidstate === 0">未开始</span>
                                <span v-if="scope.row.outbidstate === 1">竞拍中</span>
                                <span v-if="scope.row.outbidstate === 2">已结束</span>
                                <span v-if="scope.row.outbidstate === 3">已结束</span>
                                <span v-if="scope.row.outbidstate === 4">等待结果</span>
                                <span v-if="scope.row.outbidstate === 5">公示期</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="areaname"
                                header-align="center"
                                align="center"
                                label="标的物所在地">
                            <template slot-scope="scope">
                                    {{scope.row.areaname}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="outbidprice"
                                header-align="center"
                                align="center"
                                label="中标价格">
                            <template slot-scope="scope">
                                    {{scope.row.outbidprice}}
                            </template>
                        </el-table-column>


                        <el-table-column label="详情" align="center" width="200">
                            <template slot-scope="scope">
                                <el-button type="primary" size="small"
                                           @click="downLosadFile(scope.row)">项目详情
                                </el-button>
                                <el-button type="primary" size="small"
                                           @click="fillTax(scope.row)">填写发票
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--       分页-->
                    <div class="block" align="right">
                        <el-pagination
                                @size-change="sizeChangeHandle5"
                                @current-change="currentChangeHandle5"
                                :current-page="pageIndex5"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize5"
                                :total="totalPage5"
                                layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </div>
                <!-- 发票填写页面-->
                <TaxView v-if="taxVewvisible" ref="taxVewref"></TaxView>
                <!--  未中标   -->
                <div class="body_right" v-if="order == 6 ? !contract : contract">
                    <el-table v-if="hetong == 0" :data="notInBidList" border style="width: 100%">
                        <el-table-column
                                prop="projectno"
                                header-align="center"
                                align="center"
                                label="项目编号">
                        </el-table-column>
                        <el-table-column
                                prop="targetname"
                                header-align="center"
                                align="center"
                                label="项目名称">
                            <template slot-scope="scope">
                                <span @click="gotoEcCollectionsDetails(scope.row)" class="gotoDetails">
                                    {{scope.row.targetname}}
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column
                                prop="outbidstate"
                                header-align="center"
                                align="center"
                                label="竞价状态"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.outbidstate === 0">未开始</span>
                                <span v-if="scope.row.outbidstate === 1">竞拍中</span>
                                <span v-if="scope.row.outbidstate === 2">已结束</span>
                                <span v-if="scope.row.outbidstate === 3">已结束</span>
                                <span v-if="scope.row.outbidstate === 4">等待结果</span>
                                <span v-if="scope.row.outbidstate === 5">公示期</span>
                            </template>
                        </el-table-column>
                        <af-table-column
                                prop="audituser"
                                header-align="center"
                                align="center"
                                label="审批人"
                        ></af-table-column>
                        <!--                                <el-table-column label="操作" align="center" width="200">-->

                        <!--                                </el-table-column>-->
                    </el-table>
                    <!--       分页-->
                    <div class="block" align="right">
                        <el-pagination
                                @size-change="sizeChangeHandle6"
                                @current-change="currentChangeHandle6"
                                :current-page="pageIndex6"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize6"
                                :total="totalPage6"
                                layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </div>
                <!--                我的收藏-->
                <div class="body_right" v-if="order == 10 ? !collections : collections">
                    <el-table v-if="hetong == 0" :data="collectionsList" border style="width: 100%">
                        <el-table-column
                                prop="projectno"
                                header-align="center"
                                align="center"
                                label="项目编号">
                        </el-table-column>
                        <el-table-column
                                prop="targetname"
                                header-align="center"
                                align="center"
                                label="项目名称">
                        </el-table-column>

                        <el-table-column
                                prop="begintime"
                                header-align="center"
                                align="center"
                                label="竞价开始时间">
                        </el-table-column>
                        <el-table-column
                                prop="areaname"
                                header-align="center"
                                align="center"
                                label="标的物所在地">
                        </el-table-column>
                        <el-table-column
                                prop="outbidstate"
                                header-align="center"
                                align="center"
                                label="竞价状态"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.outbidstate === 0">未开始</span>
                                <span v-if="scope.row.outbidstate === 1">拍卖中</span>
                                <span v-if="scope.row.outbidstate === 2">已拍下</span>
                                <span v-if="scope.row.outbidstate === 3">已流拍</span>
                                <span v-if="scope.row.outbidstate === 4">等待结果</span>
                                <span v-if="scope.row.outbidstate === 5">公示期</span>
                            </template>
                        </el-table-column>
                        <af-table-column
                                prop="audituser"
                                header-align="center"
                                align="center"
                                label="审批人"
                        ></af-table-column>
                        <el-table-column label="操作" align="center" width="200">
                            <template slot-scope="scope">
                                <el-button type="primary"
                                           @click="gotoEcCollectionsDetails(scope.row)">详情
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--       分页-->
                    <div class="block" align="right">
                        <el-pagination
                                @size-change="sizeChangeHandle3"
                                @current-change="currentChangeHandle3"
                                :current-page="pageIndex3"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize3"
                                :total="totalPage3"
                                layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </div>
                <!--   企业基本信息     -->
                <div class="body_right" v-if="order == 2 ? !disCompany : disCompany">
                    <div class="mine_right">
                        <div class="register">
                            <div class="main">
                                <el-card shadow="always">
                                    <div class="left-form">
                                        <el-form :label-position="labelPosition" label-width="200px"
                                                 :model="enterpriseInformation">
                                            <el-form-item label="企业名称:" prop="enterprisename">
                                                <el-input v-model="enterpriseInformation.enterprisename"
                                                          placeholder="企业名称" value="sfdfd"></el-input>
                                            </el-form-item>
                                            <el-form-item label="企业简称:" prop="enterpriseshortname">
                                                <el-input type="textarea"
                                                          v-model="enterpriseInformation.enterpriseshortname"
                                                          placeholder="企业简称"></el-input>
                                            </el-form-item>
                                            <el-form-item label="社会统一信用代码证号:" prop="enterprisecode">
                                                <el-input v-model="enterpriseInformation.enterprisecode"
                                                          placeholder="社会统一信用代码证号"></el-input>
                                            </el-form-item>
                                            <!--                                            <el-form-item label="开户行名称:" prop="bank">-->
                                            <!--                                                <el-input v-model="enterpriseInformation.bank" placeholder="开户行名称"></el-input>-->
                                            <!--                                            </el-form-item>-->
                                            <!--                                            <el-form-item label="开户账户:" prop="bankcode">-->
                                            <!--                                                <el-input v-model="enterpriseInformation.bankcode" placeholder="开户账户"></el-input>-->
                                            <!--                                            </el-form-item>-->
                                            <!--                                            <el-form-item label="法人名称:" prop="legal">-->
                                            <!--                                                <el-input v-model="enterpriseInformation.legal" placeholder="法人名称"></el-input>-->
                                            <!--                                            </el-form-item>-->
                                            <!--                                            <el-form-item label="法人身份证号:" prop="legalcode">-->
                                            <!--                                                <el-input v-model="enterpriseInformation.legalcode" placeholder="法人身份证号"></el-input>-->
                                            <!--                                            </el-form-item>-->
                                            <!--                                            <el-form-item label="企业注册地址:" prop="addr">-->
                                            <!--                                                <el-input v-model="enterpriseInformation.addr" placeholder="企业注册地址"></el-input>-->
                                            <!--                                            </el-form-item>-->
                                            <el-form-item label="联系电话:" prop="tel">
                                                <el-input v-model="enterpriseInformation.tel" placeholder="联系电话"
                                                          style="min-width: 200px;color: black" effect="plain"
                                                          readonly></el-input>
                                            </el-form-item>
                                        </el-form>
                                        <el-button type="danger" @click="dataFormSubmitHandle()">保存</el-button>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                用户基本信息管理-->
                <div class="body_right" v-if="order == 1 ? !disMember : disMember">
                    <div class="mine_right">
                        <div class="register">
                            <div class="main">
                                <el-card shadow="always">
                                    <div class="left-form">
                                        <el-form :label-position="labelPosition" label-width="200px"
                                                 :model="memberInfo">
                                            <el-form-item label="联系人姓名:">
                                                <el-input v-model="memberInfo.membername"
                                                          placeholder="联系人姓名"></el-input>
                                            </el-form-item>
                                            <el-form-item label="身份证号:">
                                                <el-input v-model="memberInfo.membercode" placeholder="身份证号"></el-input>
                                            </el-form-item>
                                            <el-form-item label="手机号:">
                                                <el-input v-model="memberInfo.tel" placeholder="手机号"
                                                          :disabled="true"></el-input>
                                            </el-form-item>
                                            <el-form-item label="性别:">
                                                <el-select v-model="memberInfo.sex" placeholder="请选择">
                                                    <el-option
                                                            v-for="item in options"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="出生日期:">
                                                <el-date-picker type="date" placeholder="出生日期" value-format="yyyy-MM-dd"
                                                                v-model="memberInfo.birthday"></el-date-picker>
                                            </el-form-item>
                                        </el-form>
                                        <span style="position: absolute; display: table-cell; white-space: nowrap; padding: 7px; color: #c85808;margin-left: 5%;" v-if="memberInfo.blacklist == 1">您已被列入黑名单，请联系管理员!</span>
                                        <el-button type="danger" @click="dataFormSubmitHandle()" :disabled ="memberInfo.blacklist == 1">保存</el-button>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                    </div>
                </div>

                <!--                消息管理-->
                <div class="body_right" v-if="order==99? !dismsg : dismsg">
                    <el-table v-if="hetong == 0" :data="messageList" border style="width: 100%">
                        <el-table-column
                                prop="projectno"
                                header-align="center"
                                align="center"
                                label="项目编号">
                        </el-table-column>
                        <el-table-column
                                prop="targetname"
                                header-align="center"
                                align="center"
                                label="项目名称">
                        </el-table-column>
                        <el-table-column
                                prop="msg"
                                header-align="center"
                                label="消息内容"
                                :show-overflow-tooltip="true"
                        >
                            <template slot-scope="rowaa">{{rowaa.row.msg}}</template>
                        </el-table-column>
                        <el-table-column
                                prop="read"
                                header-align="center"
                                align="center"
                                label="状态"
                        >
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.readstate === 0" size="small" type="danger">未读</el-tag>
                                <el-tag v-else size="small" type="success">已读</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="200">
                            <template slot-scope="scope">
                                <el-button type="primary"
                                           @click="gotoMesDetails(scope.row)">查看
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--       分页-->
                    <div class="block" align="right">
                        <el-pagination
                                @size-change="sizeChangeHandle99"
                                @current-change="currentChangeHandle99"
                                :current-page="pageIndex99"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize99"
                                :total="totalPage99"
                                layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </div>

            </div>
        </div>
        <el-dialog
                title="消息详情"
                :visible.sync="dialogVisible"
                width="60%"
        >
            <el-card class="box-card">
                <div class="main-title">{{messagebody.targetname}}</div>
                <div class="main-body">
                    <div class="body-table">
                        <div class="table-list">
                            <div class="card-message mt-3 mb-3">
                                <div class="card-message_b card_size">消息内容：{{messagebody.msg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">返  回</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import UploadService from "../services/UploadFilesService";
    import flowpath from "./personalCenter_add.vue";
    import fileView from "./FileView"
    import TaxView from "./TaxView"
    export default {
        name: "personalCenter",
        components: {
            flowPath: flowpath,fileView,TaxView
        },
        data() {
            return {
                taxVewvisible:false,
                fileVewvisible:false,
                //小红点的v-if
                whitedian_up: false,
                whitedian_down: false,

                messagebody: {
                    targetname: "",
                    msg: "",
                },
                //查看消息弹出dailog
                dialogVisible: false,
                disMemberdis: false,
                disCompanydis: false,
                dismsg: false,
                displayuser: true,
                dataRule: {
                    enterprisename: [
                        {required: true, message: '企业名称不能为空', trigger: 'blur'}
                    ],
                    membername: [
                        {required: true, message: '姓名不能为空', trigger: 'blur'}
                    ],
                    membercode: [
                        {required: true, message: '身份证号不能为空', trigger: 'blur'}
                    ],
                    sex: [
                        {required: true, message: '性别不能为空', trigger: 'blur'}
                    ],
                    tel: [
                        {required: true, message: '电话不能为空', trigger: 'blur'}
                    ],
                    job: [
                        {required: true, message: '职务不能为空', trigger: 'blur'}
                    ],
                },
                activeTabName: 'first',
                ziLiaovisible: false,
                fileInfos: [],
                fileAddress: '',
                indexActiveName: '0',
                activeName: '0',
                activeBidding: '0',
                dataList: [],
                auctionParticipateList: [],
                auctionDataList: [],
                biddingDataList: [],
                biddingList: [],
                notInBidList: [],
                bidingInList: [],
                collectionsList: [],
                messageList: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                pageIndex1: 1,
                pageSize1: 10,
                totalPage1: 0,
                pageIndex2: 1,
                pageSize2: 10,
                totalPage2: 0,
                pageIndex3: 1,
                pageSize3: 10,
                totalPage3: 0,

                pageIndex4: 1,
                pageSize4: 10,
                totalPage4: 0,
                pageIndex5: 1,
                pageSize5: 10,
                totalPage5: 0,
                pageIndex6: 1,
                pageSize6: 10,
                totalPage6: 0,

                pageIndex99: 1,
                pageSize99: 10,
                totalPage99: 0,

                activeIndex: '1',
                order: 7,
                disOrder: false,
                disDetaile: false,
                disUser: false,
                disCompany: false,
                disMember: false,
                disPeople: false,
                contract: false,
                collections: false,
                active: '1',
                basicInformation: {},
                enterpriseInformation: {
                    id: '',
                    membership: '',
                    enterprisenscale: '',
                    enterprisename: '',
                    industry: '',
                    productname: '',
                    enterprisenet: '',
                    location: '',
                    enterpriseshortname: '',
                    enterprisecode: '',
                    operatingyears: '',
                    bank: '',
                    bankcode: '',
                    legal: '',
                    legalcode: '',
                    addr: '',
                    tel: '',
                    areacode: '',
                    tenantid: '',
                    auditstate: '',
                    is_delete: '',
                    auditTime: '',
                    createTime: '',
                    updateTime: '',
                    auditer: '',
                    creater: '',
                    modifier: ''
                },
                user: {
                    membername: null, //姓名
                    membercode: null, //身份证赋值
                    sex: null, //性别
                    job: null, //职务
                    tel: null, //电话
                    enterprisecode: null, //企业码
                    password: null, //密码
                },
                memberInfo: {
                    id: '',
                    enterpriseid: '',
                    enterprisename: '',
                    membername: '',
                    tel: '',
                    tenantid: '',
                    sex: '',
                    birthday: '',
                    isloading: '',
                    enterprisenscale: '',
                    enterprisecode: '',
                    industry: '',
                    location: '',
                    enterpriseshortname: '',
                    legal: '',
                    legalcode: '',
                    addr: '',
                    blacklist:''

                },
                labelPosition: 'left',
                contactList: [],
                auctionWinningList: [],
                // processList: [{targetid:"d27d4878-f6c8-4424-b82c-5cde55ee3577",outbidstate:"3",productname:"测试产品2",bidname:"2020-TYTS-0001",quantity:"8400",price:"400",begintime:"2020/11/06 09:30:00",endtime:"2020/11/06 11:30:00",delivery:"测试交割地址2",deposit:"5",companyname:"测试采购单位2"}],
                processList: [],
                hetong: 0,
                options: [
                    {
                        value: 0,
                        label: '男'
                    },
                    {
                        value: 1,
                        label: '女'
                    },
                    {
                        value: 2,
                        label: '保密'
                    }
                ]
            }
        },
        mounted() {
            this.message(5);
        },
        activated() {
            this.fileInfos = []
            this.memberInfo = this.getUser()
            this.enterpriseInformation.enterprisename = this.getUser().membername
            if (this.getUser().enterpriseid) {
                this.enterpriseInformation.enterprisename = this.getUser().enterprisename;
                this.enterpriseInformation.enterpriseshortname = this.getUser().enterpriseshortname
                this.enterpriseInformation.enterprisecode = this.getUser().enterprisecode
                this.enterpriseInformation.id = this.getUser().enterpriseid
                // this.enterpriseInformation.enterprisecode = this.getUser().legalcode
            }
            this.enterpriseInformation.tel = this.getUser().tel
            if (this.memberInfo.isloading == 0) {
                this.disMemberdis = true
                this.disCompanydis = false
            } else {
                this.disMemberdis = false
                this.disCompanydis = true
            }
            localStorage.getItem('user')
            //获取登录信息
            this.getLogState();
            //获取用户基础信息
            this.getUserData();
            if (this.$route.query.values == 3) {
                this.disPeople = false
                this.order = 3
            }
        },
        methods: {
            showFile(targetId) {
                this.fileVewvisible = true;
                this.$nextTick(() => {
                    this.$refs.fileVewref.dataForm.targetid = targetId
                    this.$refs.fileVewref.init(targetId)
                })
            },
            fillTax(parm) {
                this.taxVewvisible = true;
                this.$nextTick(() => {
                    this.$refs.taxVewref.dataForm.targetid = parm.targetid
                    this.$refs.taxVewref.dataForm.outbidresultid = parm.id
                    this.$refs.taxVewref.init(parm.targetid,parm.id)
                })
            },
            handleClick(tab, event) {

                if (tab.name == 'first') {
                    this.bidIn();
                }
                if (tab.name == 'second') {
                    this.bidIn();
                }
                if (tab.name == 'third') {
                    this.bidIn();
                }
            },
            downLosadFile(e) {
                if (e.fileAddress) {
                    UploadService.getFiles(e.fileAddress).then((response) => {
                        this.fileInfos = response.data
                    })
                }
                this.ziLiaovisible = true;

                this.$nextTick(() => {
                    this.$refs.flowpathref.init(e.targetid, e.targetname)
                })
            },
            handleOpen(key, keyPath) {

            },
            handleClose(key, keyPath) {
            },
            ...mapActions(["setToken","setUser", "setShowLogin", "setShoppingCart"]),
            ...mapGetters(["getUser", "getNum"]),
            ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
            ...mapGetters(["getUser", "getNum"]),
            //竞买1
            // 每页数
            sizeChangeHandle2(val) {
                this.pageSize2 = val
                this.pageIndex2 = 1
                //调用查询信息接口
                this.getDataList()
            },
            // 当前页
            currentChangeHandle2(val) {
                this.pageIndex2 = val
                //调用查询信息接口
                this.getDataList()
            },

            // 竞买订单列表每页数
            sizeChangeHandle1(val) {
                this.pageSize = val
                this.pageIndex = 1
                //调用查询信息接口
                this.getDataList1('0')
            },
            // 竞买订单列表当前页
            currentChangeHandle1(val) {
                this.pageIndex = val
                //调用查询信息接口
                this.getDataList1('0')
            },
            //合同列表
            selectedTab(value) {
                this.hetong = value;
                this.$fetch('pctcontract/contractList', {
                    enterprisecode: this.getUser().enterprisecode
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        this.processList = data.page.list;
                        this.totalPage3 = data.page.totalCount
                    } else {
                        this.processList = []
                        this.totalPage3 = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            // 收藏
            sizeChangeHandle3(val) {
                this.pageSize3 = val
                this.pageIndex3 = 1
                //调用查询信息接口
            },
            // 收藏
            currentChangeHandle3(val) {
                this.pageIndex3 = val
            },
            // 中标
            sizeChangeHandle5(val) {
                this.pageSize5 = val
                this.pageIndex5 = 1
                //调用查询信息接口
                this.bidIn()
                this.getredisresultupordown()
            },
            // 中标
            currentChangeHandle5(val) {
                this.pageIndex5 = val
                this.bidIn()
                this.getredisresultupordown()
            },
            // 未中标
            sizeChangeHandle6(val) {
                this.pageSize6 = val
                this.pageIndex6 = 1
                this.noBidIn();
            },
            // 未中标
            currentChangeHandle6(val) {
                this.pageIndex6 = val
                this.noBidIn();
            },
            // 信息
            sizeChangeHandle99(val) {
                this.pageSize99 = val
                this.pageIndex99 = 1
                this.mymsg();

            },
            // 信息
            currentChangeHandle99(val) {
                this.pageIndex99 = val
                this.mymsg();
            },
            // 参与
            sizeChangeHandle4(val) {
                this.pageSize4 = val
                this.pageIndex4 = 1
                this.canyu();
            },
            //参与
            currentChangeHandle4(val) {
                this.pageIndex4 = val
                //调用查询信息接口
                this.canyu();
            },
            //
            gotoSign(row) {
                let formData = {
                    contractid: row.contractid,
                    signstate: row.signstate,
                    signer: row.signer
                }

                this.$post('pctcontract/esign', formData).then((data) => {
                    if (data && data.code === 0) {
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            handleDetails() {
            },
            //获取登录信息
            getLogState() {
                if (this.getUser()) {
                    this.userLogState = true;
                } else {
                    this.userLogState = false;
                }
            },
            //点击左侧菜单栏，右面显示
            canyu() {
                this.disOrder = false
                this.activeBidding = '0'
                this.$fetch('/shoppingui/bidShopping/bidInOrNot', {
                    'page': this.pageIndex4,
                    'limit': this.pageSize4,
                    'memberid': this.getUser().id,
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        this.biddingList = data.data.list;
                        this.totalPage4 = data.data.total
                    } else {
                        this.biddingList = []
                        this.totalPage = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            noBidIn() {
                this.disPeople = false
                this.activeName = '0'
                this.$fetch('/shoppingui/bidShopping/bidInOrNot', {
                    'page': this.pageIndex6,
                    'limit': this.pageSize6,
                    'bidNotIn': 'bidNotIn',
                    'memberid': this.getUser().id,
                }, true).then((data) => {

                    if (data && data.code === 0) {
                        this.notInBidList = data.data.list;
                        this.totalPage6 = data.data.total
                    } else {
                        this.auctionParticipateList = []
                        this.totalPage = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            mymsg() {
                this.disPeople = false
                this.order = 99
                // this.dismsg = true
                this.disOrder = false
                this.activeBidding = '0'
                this.$fetch('/shoppingui/bidShopping/getMessage', {
                    'order': 'DESC',
                    'orderField': 'create_time',
                    'page': this.pageIndex99,
                    'limit': this.pageSize99,
                    'memberid': this.getUser().id,
                }, true).then((data) => {

                    if (data && data.code === 0) {
                        this.messageList = data.data.list;

                        this.totalPage99 = data.data.total
                    } else {
                        this.messageList = []
                        this.totalPage99 = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            // 消息管理  点击详情查看
            gotoMesDetails(data) {
                this.dialogVisible = true;

                if (data.readstate == 0) {
                    // 修改阅读状态
                    this.changeReadstate(data)
                }
                this.messagebody.targetname = data.targetname;
                this.messagebody.msg = data.msg;
            },
            // 标记为已读
            changeReadstate(data) {
                const param = {
                    id: data.id,
                    readstate: 1
                };
                this.$post("/shoppingui/bidShopping/updateMessage", param).then((data) => {
                        if (data && data.code === 0) {
                            this.mymsg();
                        }
                    }
                );
            },
            bidIn() {
                var url = '/shoppingui/bidShopping/bidInOrNot'
                var param = {
                    'page': this.pageIndex5,
                    'limit': this.pageSize5,
                    'bidIn': 'bidIn',
                    'memberid': this.getUser().id,
                }
                if (this.activeTabName == 'second') {
                    url = '/shoppingui/bidShopping/getredisresultupordown'
                    this.$set(param, "upordown", "up:")
                }
                if (this.activeTabName == 'third') {
                    url = '/shoppingui/bidShopping/getredisresultupordown'
                    this.$set(param, "upordown", "down:")
                }
                this.disPeople = false;
                this.activeName = '0'
                this.$fetch(url, param, true).then((data) => {

                    this.whitedian_down = false;
                    if (data && data.code === 0) {
                        if (data.data.total == 0) {
                            if (this.activeTabName == 'second'){
                                this.whitedian_up = false;
                            }
                            if (this.activeTabName == 'third'){
                                this.whitedian_down = false;
                            }
                        }

                        this.bidingInList = [];
                        this.bidingInList = data.data.list;
                        this.totalPage5 = data.data.total

                    } else if (data && data.code === "2001"){
                        this.setUser("");
                        this.setToken("");
                        sessionStorage.clear();
                        localStorage.clear();
                        this.$router.push({path: '/login'});
                    } else {
                        this.bidingInList = []
                        this.totalPage5 = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                    this.daiDown();
                })
            },
            daiDown() {
                var url = '/shoppingui/bidShopping/getredisresultupordown'
                var param = {
                    'page': this.pageIndex5,
                    'limit': this.pageSize5,
                    'bidIn': 'bidIn',
                    'memberid': this.getUser().id,
                }
                this.$set(param, "upordown", "down:")
                this.$fetch(url, param, true).then((data) => {
                    if (data && data.code === 0) {
                        if (data.data.total == 0) {
                            if (this.activeTabName == 'second') {
                                this.whitedian_up = false;
                            }
                            if (this.activeTabName == 'third') {
                                this.whitedian_down = false;
                            }
                            this.whitedian_down = false;
                        } else {
                            this.whitedian_down = true;
                        }

                    } else {
                        this.bidingInList = []
                        this.totalPage5 = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            // 待上传，待下载小红点
            getredisresultupordown() {
                var param = {
                    'memberid': this.getUser().id,
                }
                this.$fetch('/shoppingui/bidShopping/getredisresultupordownCount', param, true).then((data) => {

                    if (data && data.code === 0) {
                        if (data.data.up > 0) {
                            this.whitedian_up = true;
                        } else {
                            this.whitedian_up = false;
                        }
                        if (data.data.down > 0) {
                            this.whitedian_down = true;
                        } else {
                            this.whitedian_down = false;
                        }

                    }
                })
            },
            message(value) {
                this.dismsg = false
                this.ziLiaovisible = false
                this.pageIndex3 = 1
                this.pageSize3 = 10
                this.totalPage3 = 0
                this.order = value

                if (value === 1) {
                    this.disPeople = false
                    this.disMember = false
                    this.getUserData();
                }
                if (value === 2) {
                    this.disPeople = false
                    this.disCompany = false
                    // this.getEnterprise();
                }
                // 参与
                if (value === 7) {
                    this.pageIndex4 = 1
                    this.pageSize4 = 10
                    this.totalPage4 = 0
                    this.canyu();

                }
                // 已中标
                if (value === 5) {
                    this.pageIndex5 = 1
                    this.pageSize5 = 10
                    this.totalPage5 = 0
                    this.activeTabName = 'first'
                    this.messageList = ""
                    this.bidingInList = ""
                    this.bidIn()
                    this.getredisresultupordown();
                }
                // 未中标
                if (value === 6) {
                    this.pageIndex6 = 1
                    this.pageSize6 = 10
                    this.totalPage6 = 0
                    this.noBidIn()
                }// 我的消息
                if (value === 99) {
                    this.pageIndex99 = 1
                    this.pageSize99 = 10
                    this.totalPage99 = 0
                    this.mymsg()
                }
                // 我的收藏
                if (value === 10) {
                    this.collections = false
                    this.activeName = '0'
                    this.$fetch('/shoppingui/bidShopping/pageeccollectionsList', {
                        'page': this.pageIndex3,
                        'limit': this.pageSize3,
                        'memberid': this.getUser().id,
                    }, true).then((data) => {

                        if (data && data.code === 0) {
                            this.collectionsList = data.data.list;
                            this.totalPage3 = data.data.total
                        } else {
                            this.auctionParticipateList = []
                            this.totalPage = 0
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                showClose: 'true'
                            })
                        }
                    })
                }
                //合同
                // if (value === 6) {
                //     this.disPeople = false
                //     this.selectedTab('0')
                // }
            },
            gotoContract(row) {
                window.open(row)
            },
            gotoEcCollectionsDetails(row) {
                let queryData = {};
                queryData.targetid = row.targetid;
                queryData.id = row.targetid;
                queryData.memberid = this.getUser().id;
                if (row.splitstate == 1) {
                    this.$router.push({path: "/auction_split", query: queryData});
                } else {
                    this.$router.push({path: "/auction", query: queryData});
                }

            },
            gotoDetails(targetid) {
                let queryData = {};
                queryData.targetid = targetid;
                this.$router.push({path: "/auction", query: queryData});
            },
            gotoProcurement(ptargetid) {
                let queryData = {};
                queryData.activeName = this.active;
                queryData.ptargetid = ptargetid;
                this.$router.push({path: "/product", query: queryData});
            },
            getUserData() {
                this.$fetch('/shoppingui/memberEntShopping/shoppingMem', {
                    id: this.getUser().id
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        this.memberInfo = data.data;
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                        })
                    }
                })
            },
            getEnterprise() {
                this.$fetch('/shoppingui/memberEntShopping/shoppingEnt',
                    {id: this.memberInfo.enterpriseid}
                    , true).then((data) => {
                    if (data && data.code === 0) {
                        if (data.data) {
                            this.enterpriseInformation = data.data;
                        }
                        this.$message({
                            message: '保存成功',
                            type: 'success',
                        })
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                        })
                    }
                })
            },
            getContract(outbidresultid) {
                const param = {
                    outbidresultid: outbidresultid
                };
                this.$post('pctcontract/getcontractinfo', param).then((data) => {
                    if (data && data.code === 0) {
                        if (data.tContract != null) {
                            this.contracturl = data.tContract.contracturl;
                            this.drawerflg = true;
                        } else {
                            this.$notify({
                                title: '警告',
                                message: '请等待平台进行合同审核',
                                offset: 100,
                                type: 'warning'
                            })
                        }
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
            handleClickTwo(tab, event) {
                if (tab.index == '0') {
                    //竞卖合同
                    this.$fetch('pctcontract/contractList', {
                        bid: '0',
                        membercode: this.getUser().membercode,
                        enterprisecode: this.getUser().enterprisecode
                    }, true).then((data) => {
                        if (data && data.code === 0) {
                            this.auctionParticipateList = data.page.list;
                            this.totalPage = data.page.totalCount
                        } else {
                            this.auctionParticipateList = []
                            this.totalPage = 0
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                showClose: 'true'
                            })
                        }
                    })
                } else {
                    //竞买合同
                    this.$fetch('tpctcontract/contractList', {
                        bid: '1',
                        membercode: this.getUser().membercode,
                        enterprisecode: this.getUser().enterprisecode
                    }, true).then((data) => {
                        if (data && data.code === 0) {
                            this.auctionParticipateList = data.page.list;
                            this.totalPage = data.page.totalCount
                        } else {
                            this.auctionParticipateList = []
                            this.totalPage = 0
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                showClose: 'true'
                            })
                        }
                    })
                }
            },

            biddingClick(tab, event) {
                if (tab.index == '0') {
                    //我参与的
                    this.$fetch('ppcoutbidresultinfo/selectOutbidresultinfoListbyself', {
                        bid: '0',
                        membercode: this.getUser().membercode,
                        enterprisecode: this.getUser().enterprisecode
                    }, true).then((data) => {
                        if (data && data.code === 0) {
                            this.biddingList = data.page.list;
                            this.totalPage = data.page.totalCount
                        } else {
                            this.biddingList = []
                            this.totalPage = 0
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                showClose: 'true'
                            })
                        }
                    })
                } else {
                    //我中标的
                    // this.$fetch('ppcoutbidresultinfo/selectOutbidresultinfoListbyself', {
                    //     bid: '1',
                    //     membercode: this.getUser().membercode,
                    //     enterprisecode: this.getUser().enterprisecode
                    // }, true).then((data) => {
                    //     if (data && data.code === 0) {
                    //         this.biddingList = data.page.list;
                    //         this.totalPage = data.page.totalCount
                    //     } else {
                    //         this.biddingList = []
                    //         this.totalPage = 0
                    //         this.$message({
                    //             message: data.msg,
                    //             type: 'error',
                    //             showClose: 'true'
                    //         })
                    //     }
                    // })
                }
            },
            getIndexData() {
                //竞卖列表
                this.$fetch('pcoutbidresultinfo/selectOutbidresultinfoListbyself', {
                    bid: '0',
                    membercode: this.getUser().membercode,
                    enterprisecode: this.getUser().enterprisecode
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        this.auctionDataList = data.page.list;
                        this.totalPage = data.page.totalCount
                    } else {
                        this.auctionDataList = []
                        this.totalPage = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
                //竞买列表
                // this.$fetch('ppcoutbidresultinfo/selectOutbidresultinfoListbyself', {
                //     bid: '0',
                //     membercode: this.getUser().membercode,
                //     enterprisecode: this.getUser().enterprisecode
                // }, true).then((data) => {
                //     if (data && data.code === 0) {
                //         this.biddingDataList = data.page.list;
                //         this.totalPage = data.page.totalCount
                //     } else {
                //         this.biddingDataList = []
                //         this.totalPage = 0
                //         this.$message({
                //             message: data.msg,
                //             type: 'error',
                //             showClose: 'true'
                //         })
                //     }
                // })
            },
            // 提交表单 提交企业和会员
            dataFormSubmitHandle() {
                this.enterpriseInformation.userId = this.getUser().id
                let param = this.enterpriseInformation
                let saveOrUpdateUrl = '/shoppingui/memberEntShopping/shopEntSave'
                if (this.order === 1) {
                    param = this.memberInfo;
                    saveOrUpdateUrl = '/shoppingui/memberEntShopping/shoppingMemUpdate'
                }
                if (this.memberInfo.enterpriseid) {
                    saveOrUpdateUrl = '/shoppingui/memberEntShopping/shopEntUpdate'
                }
                this.$post(saveOrUpdateUrl, param, true).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '修改成功，重新登录！',
                            type: 'success',
                            showClose: 'true'
                        })
                        this.setUser("");
                        this.setToken("");
                        sessionStorage.clear();
                        localStorage.clear()
                        this.$router.push({path: "/login"});
                    } else {
                        this.biddingList = []
                        this.totalPage = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                });
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                //调用查询信息接口
                this.getAllDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                //调用查询信息接口
                this.getAllDataList()
            },
            //获取企业信息
            getenterprise() {

            }
        }
    }
</script>

<style scoped>
    @import "../assets/css/index.css";
    @import "../assets/css/page/personalCenter.scss";
    @import "../assets/css/page/mine.scss";
</style>
